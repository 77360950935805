import React, { useState } from 'react';
import { Box } from '@mui/system';
import {
  Button,
  Container,
  Typography,
  CircularProgress,
  Dialog,
  DialogContentText,
} from '@mui/material';
import { DocumentData } from '@google-cloud/firestore';
import { FormTextField } from '../custom/CustomComponents';
import { Print } from '@mui/icons-material';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { FormIds, submitForm } from '../../services';

export interface IRequisitionFormData {
  requestedBy: string; // Name of the person requesting
  ministry: string; // Name of the ministry
  requestDate: string; // Date of the request
  dateNeeded: string; // Date when the request is needed
  budgetedAmount: number; // Amount budgeted
  amountRequested: number; // Amount being requested
  checkPayableTo: string; // Payee for the check
  reasonForRequest: string; // Detailed reason for the request
  submittedBy: string; // Name of the person submitting the form
  ministryLeader: string; // Ministry leader's name
  trusteeSignature: string; // Trustee's signature
}

function formatDate(date: Date): string {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
}

const RequisitionForm = ({ form = null }: { form?: DocumentData | null }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [formData, setFormData] = useState<IRequisitionFormData>({
    ministry: form?.ministry ?? '',
    requestDate: form?.requestDate ?? formatDate(new Date()),
    requestedBy: form?.requestedBy ?? '',
    dateNeeded: form?.dateNeeded ?? formatDate(new Date()),
    budgetedAmount: form?.budgetedAmount ?? '',
    amountRequested: form?.amountRequested ?? '',
    checkPayableTo: form?.checkPayableTo ?? '',
    reasonForRequest: form?.reasonForRequest ?? '',
    submittedBy: form?.submittedBy ?? '',
    ministryLeader: form?.ministryLeader ?? '',
    trusteeSignature: form?.trusteeSignature ?? '',
  });

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  // const handleChangex = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setFormData({
  //     ...formData,
  //     [event.target.name]: event.target.value,
  //   });
  // };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);
    submitForm(FormIds.requisitionForm, formData)
      .then(() => {
        setShowModal(true);
      })
      .catch(() => {
        alert('Error Submitting');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const { ministry, requestedBy, reasonForRequest, amountRequested } = formData;
  const isDisabled = !(
    ministry &&
    requestedBy &&
    reasonForRequest &&
    amountRequested
  );

  const formDisabled = form !== null;

  const print = () => {
    window.print();
  };
  return (
    <Box sx={{ margin: '2rem' }}>
      <Container sx={{ width: { md: '50%', xs: '100%' } }}>
        <Typography variant="h5" align="center" gutterBottom>
          NEW MT. ZION MISSIONARY BAPTIST CHURCH LAKELAND CHECK AND PURCHASE
          REQUISITION FORM
        </Typography>

        {formDisabled && (
          <div className="no-print">
            <Button variant="contained" startIcon={<Print />} onClick={print}>
              Print Completed Form
            </Button>
          </div>
        )}

        <Box sx={{ borderTop: '2px black solid', pt: 2, mt: 2 }}>
          <Typography variant="body1" align="left" paragraph>
            This form accurately and responsibly documents the flow of church
            funds for purchases and check distribution. Receipts and the
            appropriate documentation are submitted within 48 hours of all
            purchases to explain how and why funds are used. All purchases are
            made within the operating budget guidelines, and special review and
            approval are required for all unbudgeted expenditures and
            expenditures that exceed the budgeted amount. New Mt. Zion is
            tax-exempt, and the church’s tax identification number is used for
            all purchases. The Trustee Ministry and The Business Manager review
            and approve requisitions in a timely manner. Pastoral input is
            obtained when needed.{' '}
          </Typography>
        </Box>
        <Box sx={{ borderBottom: '2px black solid', pt: 2, mt: 2, mb: 2 }}>
          <Typography variant="h6">CHECK AND PURCHASE GUIDELINES</Typography>
          <Typography>
            <ol>
              <li>
                A requisition form must be submitted for all the funds the
                church releases. Electronic submission of forms is encouraged.
              </li>
              <li>
                The ministry leader must sign and submit all requests based on
                allocated budgeted funds.
              </li>
              <li>
                Invoices, receipts, and packing sheets must be submitted within
                48 hours for all purchases and are attached to the requisition
                form.
              </li>
              <li>Cash advances are approved in rare instances.</li>
              <li>
                Vendor estimates and order forms must be approved in advance. A
                vendor checklist is required to verify licensure and insurance.
                All checks will be issued in the vendor’s business name.
              </li>
              <li>
                All honorariums are paid by check with the appropriate
                documentation (includes W-9)
              </li>
            </ol>
          </Typography>
        </Box>
        <form onSubmit={handleSubmit}>
          <FormTextField
            disabled={formDisabled}
            fullWidth
            margin="normal"
            label="Requested By"
            name="requestedBy"
            value={formData.requestedBy}
            onChange={handleChange}
          />
          <FormTextField
            disabled={formDisabled}
            fullWidth
            margin="normal"
            label="Ministry"
            name="ministry"
            value={formData.ministry}
            onChange={handleChange}
          />
          <FormTextField
            disabled={formDisabled}
            fullWidth
            margin="normal"
            label="Date"
            name="requestDate"
            type="date"
            value={formData.requestDate}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <FormTextField
            disabled={formDisabled}
            fullWidth
            margin="normal"
            label="Date Needed"
            name="dateNeeded"
            type="date"
            value={formData.dateNeeded}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <FormTextField
            disabled={formDisabled}
            fullWidth
            margin="normal"
            label="Budget Amount"
            name="budgetedAmount"
            value={formData.budgetedAmount}
            onChange={handleChange}
          />
          <FormTextField
            disabled={formDisabled}
            fullWidth
            margin="normal"
            label="Amount Requested"
            name="amountRequested"
            value={formData.amountRequested}
            onChange={handleChange}
          />
          <FormTextField
            disabled={formDisabled}
            fullWidth
            margin="normal"
            label="Check Payable to"
            name="checkPayableTo"
            value={formData.checkPayableTo}
            onChange={handleChange}
          />
          <FormTextField
            disabled={formDisabled}
            fullWidth
            margin="normal"
            label="Reason for Request"
            name="reasonForRequest"
            value={formData.reasonForRequest}
            onChange={handleChange}
            multiline
            rows={3}
          />
          <FormTextField
            disabled={formDisabled}
            fullWidth
            margin="normal"
            label="Submitted By"
            name="submittedBy"
            value={formData.submittedBy}
            onChange={handleChange}
          />
          <FormTextField
            disabled={formDisabled}
            fullWidth
            margin="normal"
            label="Ministry Leader"
            name="ministryLeader"
            value={formData.ministryLeader}
            onChange={handleChange}
          />
          <FormTextField
            disabled={formDisabled}
            fullWidth
            margin="normal"
            label="Trustee Signature"
            name="trusteeSignature"
            value={formData.trusteeSignature}
            onChange={handleChange}
          />

          {!form && (
            <Button
              variant="contained"
              color="primary"
              type="submit"
              style={{ marginTop: '1em' }}
              disabled={isDisabled || isLoading}
              startIcon={isLoading ? <CircularProgress size={20} /> : null}
            >
              {isLoading ? 'Submitting...' : 'Submit'}
            </Button>
          )}

          <Dialog
            open={showModal}
            onClose={() => {}}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Form Submitted</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Form submitted
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  window.location.href = '/';
                }}
              >
                OK
              </Button>
            </DialogActions>
          </Dialog>
        </form>
        {/* <Typography variant="body1" align="center" paragraph sx={{ mt: 2 }}>
          Thank you for completing the 2025 Budget Request Form. Please contact
          the administrative office with questions.
        </Typography> */}
      </Container>
    </Box>
  );
};

export default RequisitionForm;
