/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { Checkbox } from '@mui/material';
import { updateDoc, doc } from 'firebase/firestore';
import { db } from '../../App';
//import { Checkbox } from '@mui/material';
// import { doc, updateDoc } from 'firebase/firestore';
// import { db } from '../../App';

const SubmittedBreastCancer = ({ forms }: { forms: any[] }) => {
  const [sorting, setSorting] = React.useState<SortingState>([
    {
      id: 'firstName',
      desc: false,
    },
    {
      id: 'lastName',
      desc: false,
    },
  ]);

  const markAsPaid = async (id: string, paid: boolean) => {
    const ref = doc(db, 'forms', id);

    await updateDoc(ref, {
      paid,
    });
  };

  const setComplimentary = async (id: string, complimentaryShirts: boolean) => {
    const ref = doc(db, 'forms', id);

    await updateDoc(ref, {
      complimentaryShirts,
    });
  };

  const columns = React.useMemo<ColumnDef<any>[]>(
    () => [
      // {
      //   header: 'id',
      //   accessorKey: 'id',
      //   cell: (v) => <div>{v.row.original.index}</div>,
      // },
      // {
      //   header: 'id',
      //   accessorKey: 'id',
      //   cell: (v) => (
      //     <a
      //       target="_blank"
      //       href={`https://console.firebase.google.com/project/nmzlakeland-a8568/firestore/data/~2Fforms~2F${v.row.original.id}`}
      //       rel="noreferrer"
      //     >
      //       {v.row.original.id}
      //     </a>
      //   ),
      // },
      {
        header: 'First Name',
        accessorKey: 'firstName',
      },
      {
        header: 'Last Name',
        accessorKey: 'lastName',
      },
      // {
      //   header: 'email',
      //   accessorKey: 'email',
      // },
      {
        header: 'Cancer Survivor',
        accessorKey: 'survivor',
      },
      {
        header: 'Ordered T-Shirt or Cap',
        accessorKey: 'orderTshirt',
        cell: (v) => (v.getValue() === true ? 'Yes' : 'No'),
      },
      {
        header: 'T-Shirt Size(s)',
        accessorKey: 'tshirtSize',
        cell: (v) =>
          v.row.original.orderTshirt ? (
            <div style={{ whiteSpace: 'break-spaces', lineHeight: '1.5rem' }}>
              {(v as any)
                .getValue()
                .split(',')
                .map((x: string) => {
                  if (x.endsWith('-m')) {
                    return ` - ${x.replace('-m', ' (without butterflies)')}`;
                  }
                  if (x === 'cap-r') return ` - Cap with rhinestone`;
                  if (x === 'cap') return ` - Cap with no rhinestone`;
                  return ` - ${x}`;
                })
                .join('\n')}
            </div>
          ) : (
            <></>
          ),
      },
      // {
      //   header: 'Paid?',
      //   accessorKey: 'paid',
      //   cell: (v) => <>{v.row.original.paid === true ? 'Yes' : 'No'}</>,
      // },
      // {
      //   header: 'Complimentary Shirts',
      //   accessorKey: 'complimentaryShirts',
      //   cell: (v) => (
      //     <>{v.row.original.complimentaryShirts === true ? 'Yes' : 'No'}</>
      //   ),
      // },
      // {
      //   header: 'Email',
      //   accessorKey: 'email',
      // },
      // {
      //   header: 'Phone',
      //   accessorKey: 'phone',
      // },
      // {
      //   header: 'Paid?',
      //   accessorKey: 'paid',
      //   cell: (v) => (
      //     <Checkbox
      //       onChange={(e) => markAsPaid(v.row.original.id, e.target.checked)}
      //       checked={v.row.original.paid === true}
      //     />
      //   ),
      // },
      // {
      //   header: 'Complimentary Shirts',
      //   accessorKey: 'complimentaryShirts',
      //   cell: (v) => (
      //     <Checkbox
      //       onChange={(e) =>
      //         setComplimentary(v.row.original.id, e.target.checked)
      //       }
      //       checked={v.row.original.complimentaryShirts === true}
      //     />
      //   ),
      // },
    ],
    []
  );

  const data = forms;

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <div className="p-2">
      <table style={{ borderCollapse: 'collapse' }}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    style={{
                      border: '1px solid black',
                      padding: '10px',
                      backgroundColor: '#ddd',
                      cursor: 'pointer',
                    }}
                  >
                    {header.isPlaceholder ? null : (
                      <div
                        {...{
                          className: header.column.getCanSort()
                            ? 'cursor-pointer select-none'
                            : '',
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {{
                          asc: ' 🔼',
                          desc: ' 🔽',
                        }[header.column.getIsSorted() as string] ?? null}
                      </div>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table
            .getRowModel()
            .rows.slice(0, 1000)
            .map((row) => {
              return (
                <tr
                  key={row.id}
                  style={{
                    color: row.original.survivor === 'Yes' ? 'red' : 'black',
                  }}
                >
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td
                        key={cell.id}
                        style={{ border: '1px solid black', padding: '5px' }}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default SubmittedBreastCancer;
