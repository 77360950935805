import { Box } from '@mui/material';
import React from 'react';

const BackgroundLetter = () => {
  return (
    <Box
      sx={{
        padding: 5,
        margin: 5,
        width: {
          lg: '50%',
        },
        border: '1px solid gray',
      }}
    >
      <h2 style={{ textAlign: 'center' }}>
        NMZ Employee and Volunteer Application Procedure
      </h2>
      <p>Dear New Mt. Zion Member:</p>
      <p>
        Welcome to New Mt. Zion’s Employment and Volunteer Verification System.
        New Mt. Zion Lakeland exists to share God’s Word and His Love while
        serving as a Beacon of Light and Hope within the Lakeland Community.
        Employees and volunteers play an essential role in the operations of our
        church, and we appreciate and thank you for your interest in serving at
        New Mt. Zion Missionary Baptist Church Lakeland. As church-based
        employees or volunteers, we require you to demonstrate Christian
        behavior and serve with compassion, excellence, and love for one
        another.
      </p>
      <p>
        The church requires all employees and volunteers to complete an
        Employee/Volunteer application form and undergo an electronic
        confidential background check. Our church has partnered with Protect My
        Ministry, the leading background check firm in the nation, to ensure
        that we have the right people in the right places and the best resources
        to ensure the safety of our children, members, and church assets. As a
        convenience, the firm’s ministry mobilizer is designed to assist the
        church in providing a one-stop verification process.
      </p>

      {/* <p>
        <b>
          All New Mt. Zion leaders, employees, and volunteers must complete the
          electronic background verification process.{' '}
        </b>
        This process will be completed every three years. To begin compliance,
        please click the link below to complete the New Mt. Zion Background
        Check form and the New Mt. Zion Child Protection policy
      </p>
      <p>
        <a href="https://ministryopportunities.org/NMZLakeland">
          https://ministryopportunities.org/NMZLakeland
        </a>
      </p> */}
      <p>
        <h3 style={{ textAlign: 'center' }}>
          TRAINING FOR CHILDREN’S MINISTRY VOLUNTEERS
        </h3>
      </p>
      <p>
        In addition to the background check verification process, all Children
        {`'`}s Ministry volunteers must complete the Child Safety Training
        Course before working in this ministry. This is non-negotiable. The{' '}
        <b>Child Safety Training Course</b> is completed online and consists of
        5 lessons covering the following topics:
      </p>
      <p>
        <ol type="a">
          <li>Lesson 1: Defining Child Sexual Abuse</li>
          <li>Lesson 2: Victim Behavioral Characteristics</li>
          <li>Lesson 3: Characteristics of Child Molesters</li>
          <li>Lesson 4: Making a Difference</li>
          <li>Lesson 5: Responsibility & Reporting</li>
        </ol>
      </p>
      <p>
        The Children’s Church Child Safety Training Modules will be sent to the
        Children’s ministry volunteers electronically after successfully
        completing the background check.
      </p>

      <p>
        <h3 style={{ textAlign: 'center' }}>THE APPLICATION PROCESS</h3>
      </p>

      <p>
        Please click{' '}
        <a href="/forms/volunteer" target="_blank" rel="noreferrer">
          the link
        </a>{' '}
        below to complete the employment and Volunteer application form and give
        consent for a confidential electronic background check. Thank you again
        for your interest in serving our church. Please contact the church
        office with questions.
      </p>
      <p>
        <a href="/forms/volunteer" target="_blank" rel="noreferrer">
          Employment and Volunteer Application Form
        </a>
      </p>
      <p>New Mt. Zion Lakeland Administration</p>
    </Box>
  );
};

export default BackgroundLetter;
